import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React, {useState} from "react";
import {Link} from "react-scroll";

import Layout from "../components/Layout";
import StructuredData from "../components/StructuredData";
import "./team.scss";

const Team = ({data}) => {
  const cmsData = data.sanityTeamPage;
  const seoData = data.allSanitySeoMeta.nodes[0];
  const [isActive, setActive] = useState([]);
  return (
    <Layout seoData={seoData}>
      <StructuredData />
      <section className='teamHeadWrap'>
        <div className='headStuff'>
          <h1>{cmsData.teamTitle}</h1>
          <br />
          <div className='rowps'>
            <div className='jobsTitle'>
              <p>{cmsData.p1}</p>
              <br />
              <Link
                to='jobs'
                spy
                smooth
                duration={500}
                className='pbuttonWrap jobBtn'
              >
                View Open Positions
              </Link>
            </div>
            <div>
              <p className='bold'>{cmsData.p2}</p>
              <br />
              <p>{cmsData.p3}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='teampic full-bleed' />
      <section className='crew'>
        <div className='intro'>
          <h2>{cmsData.crew.crewTitle}</h2>
          <p>{cmsData.crew.crewDesc}</p>
        </div>
        {cmsData.crew.members.map((member) => (
          <div className='member' key={member._key}>
            <div className='memberImg'>
              <img src={member.img} alt={member.name} />
              <div className='overlay'>{member.desc}</div>
            </div>
            <h4>{member.name}</h4>
            <p className='caption'>{member.role}</p>
          </div>
        ))}
      </section>
      <section className='jobs'>
        <div className='rowps'>
          <div className='jobMeta'>
            <h2>{cmsData.lastSectionTitle}</h2>
            <br />
            <p>{cmsData.p4}</p>
            <br />
            <p>{cmsData.p5}</p>
            <br />
            <p>{cmsData.p6}</p>
          </div>
          <div id='jobs' className='jobwrap'>
            <h4>OPEN POSITIONS</h4>
            <br />
            {cmsData.jobs.map((job) => (
              <div className='job' key={job._key}>
                <h3
                  onClick={() => {
                    isActive.indexOf(job._key) === -1
                      ? setActive([...isActive, job._key])
                      : setActive([]);
                  }}
                >
                  {job.name} &ensp;{" "}
                  <h4 className='light' style={{color: job.color}}>
                    {job.dept}
                  </h4>
                </h3>
                {isActive.indexOf(job._key) !== -1 && (
                  <div className='jobdescWrap'>
                    <br />
                    <p>{job.desc}</p>
                    <br />
                    <OutboundLink
                      href={job.link}
                      className='pbuttonWrap jobBtn'
                    >
                      APPLY NOW!
                    </OutboundLink>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    allSanitySeoMeta(filter: {page: {eq: "team"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    sanityTeamPage {
      teamTitle
      p3
      p2
      p1
      crew {
        crewDesc
        crewTitle
        members {
          _key
          desc
          img
          name
          role
        }
      }
      p4
      p5
      p6
      jobs {
        _key
        color
        dept
        desc
        link
        name
      }
      lastSectionTitle
    }
  }
`;

export default Team;
